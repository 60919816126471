import './App.scss';
import Wrapper from './pages/wrapper/Wrapper';
import Home from './pages/home/Home';
import Playlist from './pages/playlist/Playlist';

import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";
import Play from './pages/play/Play';


const router = createBrowserRouter([
    {
        path: "/",
        element: <Wrapper />,
        children: [
            {
                index: true,
                element: <Home />,
            },
            {
                path: "playlist",
                element: <Playlist />,
            },
            {
            path: "play",
            element: <Play />,
        },]
    },
    
]);


function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
