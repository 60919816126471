import "./Footer.scss";

function Footer() {
    return (
        <div className="footer">
            Footer
        </div>
    );
}

export default Footer;
