
import { Outlet } from 'react-router-dom';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import './Wrapper.scss';

function Wrapper() {
    return (
        <div>
            <Header />
            <div className="page-body">
                <Outlet />
            </div>
            <Footer />
        </div>
    );
}

export default Wrapper;
