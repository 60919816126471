import "./Label.scss";
function Label({children, href}) {
    return (
        <a className="label" href={href}>
            <span>
                {children}
            </span>
        </a>
    );
}

export default Label
