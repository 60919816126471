import './Header.scss';
import logo from "./logo.png";

function Header() {
    return (
        <header>
            <div className="left">
                <img src={logo} alt="img"/>
                
                <h3>
                    Code Malayalam
                </h3>
            </div>
            <div className="right">
                <button>Login</button>
            </div>
        </header>
    );
}

export default Header;
