
import Label from '../../components/label/Label';
import './Home.scss';
import img from './main.svg';
import { ImYoutube2 } from "react-icons/im";
import { IoPlay } from "react-icons/io5";
import { RiPlayList2Fill } from "react-icons/ri";


function Home() {
    return (
        <div className="home">
            <div className="home-left">
                <a href="https://www.youtube.com/watch?v=PPQpx63FhrE" target="_blank" rel="noreferrer">
                    <label> <ImYoutube2 className="youtube"/>  <IoPlay /> </label>
                </a>
                <img src={img} alt="main"/>
            </div>    
            <div className="home-right">
                <div className="caption">
                    <label className="main">കോഡിങ്</label> പഠിക്കാൻ മലയാളത്തിലെ ഒരു <label className="main">ബെസ്റ്റ്</label> ചാനൽ 
                </div>
                <div>
                    <Label href="https://www.youtube.com/@CodeMalayalam/playlists">
                        See Playlists <RiPlayList2Fill />
                    </Label>
                </div>
            </div>
        </div>
    );
}

export default Home;
